import React from "react";

function ScrollableIcon({ index, breadcrumb = [] }) {
  if (
    breadcrumb[breadcrumb.length - 1] &&
    breadcrumb[breadcrumb.length - 1].overviewIcon &&
    index === 1
  ) {
    return (
      <div
        data-uk-sticky="bottom: true; media:@l"
        className="uk-flex uk-flex-right@l uk-flex-center uk-padding-small uk-padding-remove-horizontal"
      >
        <i
          className="uk-icon"
          data-uk-icon={`icon: ${
            breadcrumb[breadcrumb.length - 1].overviewIcon
          }; ratio: 5`}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

export default ScrollableIcon;
